import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-quit-confirm-dialog',
    templateUrl: './quit-confirm-dialog.component.html',
    styleUrls: ['./quit-confirm-dialog.component.scss']
})
export class QuitConfirmDialogComponent implements OnInit {
    component;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<QuitConfirmDialogComponent>) {
                    this.component = data.component;
                }

    ngOnInit() {
    }

    closeDialog() {
        this.dialogRef.close(false);
    }

    quitAndSave() {
        this.component.dataSource.sauvegardeSaisie();
        this.dialogRef.close(true);
    }

    quitWithoutSave() {
        this.dialogRef.close(true);

    }
}
