import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-snack-error',
  templateUrl: './snack-error.component.html',
  styleUrls: ['./snack-error.component.scss']
})
export class SnackErrorComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public msg: any) { }

  ngOnInit() {
  }


}
