import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<ConfirmDialogComponent>) { }

    ngOnInit() {
    }

    closeDialog() {
        this.dialogRef.close(false);
    }
}
