import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { BASE_PATH, CollectiviteResourceService, DownloadResourceService, ConditionsGeneralesResourceService, AuthResourceService } from '@com.sofaxis/sofaxis-fonpel-espace-coll-ws-ts';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';

import { MatIconModule } from '@angular/material/icon';
import { registerLocaleData, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SnackErrorComponent } from './shared/components/snack-error/snack-error.component';
import { SnackSuccessComponent } from './shared/components/snack-success/snack-success.component';
import { SharedModule } from './shared/shared.module';
import { HelperServiceService } from './api/helper-service.service';
import { GlobalLoadingService } from './api/global-loading.service';
import { AppConfig } from './utils/app.config';
import { HttpModule } from '@angular/http';
import { ConfirmDeactivateGuard } from './shared/confirm-deactivate-guard';
import { QuitConfirmDialogComponent } from './shared/components/quit-confirm-dialog/quit-confirm-dialog.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { JustifInputDialogComponent } from "src/app/shared/components/justif-input-dialog/justif-input-dialog.component";
import { JustifInputComponent } from "src/app/shared/components/justif-input/justif-input.component";
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { WarningDialogComponent } from "src/app/shared/components/warning-dialog/warning-dialog.component";


export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

registerLocaleData(localeFr);

export function initConfig(config: AppConfig, keycloackService: KeycloakService, authResourceService: AuthResourceService) {
    return () => config.load(keycloackService, authResourceService);
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        HttpModule,
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatMomentDateModule,
        KeycloakAngularModule,
        MatSnackBarModule,
        FormsModule
    ],

    providers: [
        AuthResourceService,
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfig, KeycloakService, AuthResourceService],
            multi: true
        },
        {
            provide: BASE_PATH,
            useValue: '.',
            deps: [environment]
        },
        HelperServiceService,
        GlobalLoadingService,
        DownloadResourceService,
        CollectiviteResourceService,
        ConditionsGeneralesResourceService,
        DatePipe,
        ConfirmDeactivateGuard,

    ],
    entryComponents: [SnackErrorComponent, SnackSuccessComponent, QuitConfirmDialogComponent, ConfirmDialogComponent, WarningDialogComponent, JustifInputComponent, JustifInputDialogComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
