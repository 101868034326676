import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { CollectiviteDTO, CollectiviteResourceService, DownloadResourceService } from '@com.sofaxis/sofaxis-fonpel-espace-coll-ws-ts';
import { GlobalLoadingService } from "src/app/api/global-loading.service";
import { Decimal } from 'decimal.js/decimal';

@Injectable()

// https://stackoverflow.com/questions/35985009/angular-2-what-is-equivalent-to-root-scope
// https://stackoverflow.com/questions/34376854/delegation-eventemitter-or-observable-in-angular/35568924#35568924

export class HelperServiceService {

    private _collItemSource = new ReplaySubject<CollectiviteDTO>();

    // Observable navItem stream
    collItem$ = this._collItemSource.asObservable();

    // service command
    changeColl( coll: CollectiviteDTO ) {
        this._collItemSource.next( coll );
    }


    constructor( private globalLoadingService: GlobalLoadingService, private service: CollectiviteResourceService, private downloadService: DownloadResourceService ) {
        this.service.currentUser().subscribe( coll => {
            this.changeColl( coll );
        } );
    }

    downloadFile( idDocument, libelle ) {
        this.downloadService.downloadDocument( idDocument ).subscribe(
            result => {
                this.saveOrOpenBlob( result, libelle + '.pdf' );
            }
        );
    }

    parseFilenameFromContentDisposition( contentDisposition ): string {
        if ( !contentDisposition ) return null;
        var matches = /filename="(.*?)"/g.exec( contentDisposition );

        return matches && matches.length > 1 ? matches[1] : null;
    }

    saveOrOpenBlob( blob: Blob, blobFileName: string ) {
        if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
            window.navigator.msSaveOrOpenBlob( blob, blobFileName );
        } else {
            // pour Chrome et FireFox
            const url = window.URL.createObjectURL( blob );
            const a = document.createElement( 'a' );
            document.body.appendChild( a );
            a.setAttribute( 'style', 'display: none' );
            a.href = url;
            a.download = blobFileName;
            a.click();
            window.URL.revokeObjectURL( url );
            document.body.removeChild( a );
        }
    }

    downloadAttachment( idDocument ) {
        this.downloadService.downloadDocument( idDocument, 'response' ).subscribe(
            result => {
                const contentDisposition = result.headers.get( 'Content-Disposition' );
                const filename = this.parseFilenameFromContentDisposition( contentDisposition );

                this.saveOrOpenBlob( result.body, filename );
            }
        );
    }

    isDecimal( value ): boolean {
        try {
            new Decimal( this.normalizeNumber( value ) );
            return true;
        } catch ( e ) {
        }

        return false;
    }

    normalizeNumber( value ) {
        if ( value ) {
            let result = value.toString().split( ',' ).join( '.' );
            result = result.split( ' ' ).join( '' );
            return result;
        } else {
            return value;
        }
    }

    entries( obj ) {
        return Object.entries( obj );
    }

}
