import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material';
import { SnackSuccessComponent } from './components/snack-success/snack-success.component';
import { SnackErrorComponent } from './components/snack-error/snack-error.component';
import { QuitConfirmDialogComponent } from './components/quit-confirm-dialog/quit-confirm-dialog.component';
import { JustifInputComponent } from "src/app/shared/components/justif-input/justif-input.component";
import { JustifInputDialogComponent } from "src/app/shared/components/justif-input-dialog/justif-input-dialog.component";
import { FileUploadModule } from 'ng2-file-upload';
import { WarningDialogComponent } from './components/warning-dialog/warning-dialog.component';
import { CustomDatepickerComponent } from './components/custom-datepicker/custom-datepicker.component';


@NgModule( {
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule,
        MatDialogModule, FileUploadModule
    ],
    declarations: [ConfirmDialogComponent, SnackSuccessComponent, SnackErrorComponent, QuitConfirmDialogComponent, JustifInputComponent, JustifInputDialogComponent, WarningDialogComponent, CustomDatepickerComponent],
    exports: [JustifInputComponent, JustifInputDialogComponent, CustomDatepickerComponent]
} )
export class SharedModule {
}
