import { Component } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { NavigationEnd, Router } from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    userDetails: KeycloakProfile;
    title = 'sofaxis-fonpel-espace-coll-client';

    constructor(private keycloakService: KeycloakService, private router: Router) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const prefixe: string = 'fr.espace_clients.declarationFonpelColl.';
                let nom: string = event.url.split('/')[1];
                nom = nom.replace('#', '_');
            }
        });
    }
    async ngOnInit() {
    }
}



