import { Component, Inject } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { JustifInputDialogResponse } from './justif-input-dialog-response';
import { JustifInputDialogData } from './justif-input-dialog-data';
import { environment } from "src/environments/environment";

@Component( {
    selector: 'app-justif-input-dialog',
    templateUrl: './justif-input-dialog.component.html',
    styleUrls: ['./justif-input-dialog.component.scss']
} )
export class JustifInputDialogComponent {
    public uploader: FileUploader = new FileUploader( { url: '' } );
    public hasDropZoneOver = false;
    public uploadingFile = '';
    public errorMsg = '';
    public allowedExtensions = ['png', 'gif', 'jpg', 'jpeg', 'pdf', 'odp', 'bmp'];

    constructor(
        public dialogRef: MatDialogRef<JustifInputDialogComponent, JustifInputDialogResponse>,
        @Inject( MAT_DIALOG_DATA ) public data: JustifInputDialogData ) {
    }

    public fileOver( e: any ): void {
        this.hasDropZoneOver = e;
    }

    public dropped( e: any ): void {
        if ( e && e[0] ) {
            this.uploader.clearQueue();
            this.errorMsg = '';

            if ( this.checkExtension( e[0].name ) ) {
                if ( e[0].size <= environment.maxSizeUploadFile ) {
                    this.uploader.addToQueue( e );
                    this.uploadingFile = e[0].name;
                } else {
                    this.uploadingFile = '';
                    this.errorMsg = `La taille de votre fichier ne peut pas excéder ${environment.maxSizeUploadFile / 1024 / 1024}Mo`;
                }
            } else {
                this.uploadingFile = '';
                this.errorMsg = `Les formats de fichier autorisés sont ${this.allowedExtensions.join( ', ' )}`;
            }
        }
    }

    public cancelUpload(): void {
        this.uploader.clearQueue();
        this.uploadingFile = '';
        this.dialogRef.close( { cancel: true } );
    }

    public removeFile(): void {
        this.uploader.clearQueue();
        this.uploadingFile = '';
        this.dialogRef.close( {
            data: {
                deleted: true,
                base64: null
            }
        } );
    }

    public validUpload(): void {
        if ( this.uploader.queue.length > 0 ) {
            const rawFile = this.uploader.queue[0].file.rawFile;
            const reader = new FileReader();
            reader.readAsDataURL( rawFile.slice( 0 ) as any );
            reader.onloadend = () => {
                this.dialogRef.close( {
                    data: {
                        base64: reader.result,
                        filename: this.uploadingFile || ( this.data.value ? this.data.value.filename : undefined )
                    }
                } );
            };
        } else {
            this.errorMsg = `Pour valider, vous devez sélectionner un fichier à envoyer`;
        }
    }

    private checkExtension( filename: string ): boolean {
        const exploded = filename.split( '.' );
        return this.allowedExtensions.indexOf( exploded[exploded.length - 1] ) >= 0;
    }
}
