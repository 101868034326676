import { Injectable, Inject, Component } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { CotisationComponent } from '../home/cotisation/routes/cotisation/cotisation.component';
import { MatDialog } from '@angular/material';
import { QuitConfirmDialogComponent } from './components/quit-confirm-dialog/quit-confirm-dialog.component';

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<CotisationComponent> {

    constructor(private dialog: MatDialog) { }
    canDeactivate(target: CotisationComponent) {
        if (!target.dataSource.saved) {
            const dialogRef = this.dialog.open(QuitConfirmDialogComponent, {
                width: '450px',
                height: '250px',
                disableClose: true,
                data: {
                    component : target
                }
            });
            return dialogRef.afterClosed();
        }
        return true;
    }

}
